:root {
  --purple: #5c004f;
  --pink: #e6658b;
  --green: #3b544f;
  --lightpink: #fce6e6;
  --blue: #4B54AB;
}

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  font-family: Montserrat;
  box-sizing: border-box;
}

p {
  font-size: 19px;
}

section {
  z-index: -1;
  margin-top: -95px;
  min-height: 100vh;
  width: 100%;
  position: relative;
}

.leftAlign {
  text-align: left;
}

.rotate {
  width: 25px;
  margin: auto;
  transform: rotate(90deg);
  transition: all .2s ease-in;
}

.rotate:hover {
  transform: rotate(90deg) scale(1.1);
  cursor: pointer;
}

video.fullscreen {
  position: absolute;
  z-index: -1;
  object-fit: cover;
  width:100%;
  height:100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &::-webkit-media-controls {
     display: none !important;
  }
}

video.fullscreen.top {
  z-index: 0;
}

.content {
  z-index: 1;
  position: absolute;
  width: 50%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 30px;

  color: #fff;
}

.content h1 {
  font-size: 32px;
  padding-bottom: 20px;
}

.content p {
  font-weight: 300;
  color: #e7e7e7;
}

.menu {
  width: 100%;
  z-index: 1;

  display: grid;
  grid-template-columns: 1fr auto;

  color: #fff;
  padding: 10px 30px;

  align-items: center;

  background-color: rgba(0, 0, 0, 0.3);
}

.menu a > img {
  margin-top: -3px;
}

.menu .items {
  width: 100%;
  text-align: center;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 40px;
}

.items a {
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  font-weight: 300;
  transition: all .2s ease-in-out;
}

.items a:hover {
  font-weight: 600;
}

.postIt {
  background-position: center;
  background-size: cover;
}

.postItContainer {
  width: 80%;
  margin: auto;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.textContainer {
  padding: 50px;
  margin-bottom: 30px;

  color: var(--pink);
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 40px;
}

.textContainer p {
  opacity: 0.7;
}

h2 {
  font-size: 32px;
  margin-bottom: 20px;
}

.container {
  color: var(--purple);
}

.container {
  width: 85%;
  min-height: 600px;
  padding: 100px 0px;
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.pagePadding {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 85%;
  margin: auto;
}

.pageText {
  padding-bottom: 20px;
  color: var(--purple)
}

.pageText p {
  color: #2a2a2a;
}

.pageText h2 {
  margin-top: 40px;
}

.programs {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-column-gap: 50px;

  align-items: center;
}

.programs h2 {
  font-size: 32px;
  padding-bottom: 20px;
}

img.arrow {
  transition: all .2s ease-out;
}

img.arrow:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.arrow img {
  transition: all .2s ease-out;
}

.arrow img:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.animation {
  opacity: 0;
  animation-name: blurIn;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}

.fromBottom {
  opacity: 0;
  animation-name: fromBottom;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.pink {
  color: #e6658b;
}

.purple {
  color: #6a1368;
}

.green {
  color: #3b544f;
}

@keyframes blurIn {
  0% { opacity: 0; filter: blur(10px); }
  100% { opacity: 1; filter: blur(0px); }
}

@keyframes fromBottom {
  from { opacity: 0; transform: translateY(-30%)}
  to { opacity: 1; }
}

.lightPink {
  color: var(--lightpink);
}

.bgContainer {
  background-color: var(--lightpink);

  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 20px;
  align-items: center;

  width: 100%;
  padding: 70px 50px;

  -webkit-transition: background-color 2000ms linear;
  -ms-transition: background-color 2000ms linear;
  transition: background-color 2000ms linear;
}

.whiteBg {
  background-color: #fff;
  color: var(--lightpink);

  border-radius: 40px;
  padding: 100px;
  min-height: 530px;
  text-align: center;

  display: flex;
  align-items: center;
}

.whiteBg h2 {
  font-size: 32px;
  padding-bottom: 20px;
}

.curved {
  position: relative;
  background: var(--lightpink);
  min-height: 0;
  height: 100px;

  -webkit-transition: background-color 2000ms linear;
  -ms-transition: background-color 2000ms linear;
  transition: background-color 2000ms linear;
}

.curved-two {
  position: relative;
  background: #fff;
  min-height: 0;
  height: 100px;
  border-bottom-left-radius: 50% 100%;
  border-bottom-right-radius: 50% 100%;
}

.curved::after {
  content: '';
  border-top-left-radius: 50% 100%;
  border-top-right-radius: 50% 100%;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #fff;
  height: 100%;
}

.team {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  width: 100%;
  margin: auto;
}

h2.contact {
  font-size: 32px;
  padding-bottom: 40px;
  text-align: center;

  color: var(--purple);
}

.member {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left; /* Align text to the left inside h3 and p */
  line-height: 32px;
}

.member .text {
  flex-basis: 100%;
  padding-left: 10px;
}

span.upper {
  background-color: inherit !important;
  padding: 0 !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

.highlight {
  font-size: 17px;
  line-height: 22px;
  padding: 10px;
  border-radius: 5px;

  background-color: var(--lightpink);
  width: 100%;
  height: 100%;
}

.member div.videos {
  display: flex;
  flex-basis: 30%;
  flex-direction: column;
  align-items: center; /* Center video elements vertically */
}

.member video {
  width: 100%;
  height: auto;
  margin-bottom: 10px; /* Add space between videos */
}

.member video.surr {
  width:30px;
  height: auto;
}

.member p {
  opacity: 0.6;
  margin: 0; /* Remove default margins */
}

.member:nth-child(1) {
  color: var(--purple);
}

.member:nth-child(2) {
  color: var(--green);
}

.member:nth-child(3) {
  color: var(--blue);
}

.member:nth-child(4) {
  color: var(--pink);
}


.pinkTwo {
  background-color: var(--lightpink);
}

.centered {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.centered p {
  opacity: 0.5;
  margin-top: 10px;
  font-style: italic;
}

.burger {
  display: none;
}

.mobileMenu {
  display: none;
}

.slideDown {
  -webkit-animation: menuSlideDown 1s;
          animation: menuSlideDown 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@keyframes menuSlideDown {
  0% { -webkit-transform: translateY(-100%); transform: translateY(-100%); }
  100% { -webkit-transform: translateY(0px); transform: translateY(0px); }
}

@-webkit-keyframes menuSlideDown {
  0% { -webkit-transform: translateY(-100%); transform: translateY(-100%); }
  100% { -webkit-transform: translateY(0px); transform: translateY(0px); }
}


.cross {
  display: block;
  position: absolute;
  right: 10px;
  top: 10px;

  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.back-to-top {
  background-color: var(--pink);
  color: #fff;
  position: fixed;
  right: 50px;
  bottom: 50px;

  width: 100px;
  height: 100px;
  border-radius: 50%;

  font-size: 60px;
  line-height: 90px;
  font-weight: 600;
  text-align: center;
  padding: 0;
  margin: 0;

  transition: all .2s ease-in;

  -webkit-box-shadow: 0px 5px 17px -2px rgba(0,0,0,0.66); 
  box-shadow: 0px 5px 17px -2px rgba(0,0,0,0.66);
}

.back-to-top:hover {
  line-height: 80px;
  cursor: pointer;
}

b {
  font-weight: 600;
}

.mikael {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 30px;
  align-items: center;
}

.mikael h2 {
  margin-bottom: 0px;
}

.mikael img {
  border-radius: 40px;
  filter: drop-shadow(0px 5px 16px #a3a3a3);
}

p.subHeading {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 30px;
}

.case {
  width: 100%;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #fc8ba3;

  display: flex;
  align-items: center;
}

.text-wrapper {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  color: #fff;
}

.caseContainer {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-column-gap: 10px;
  align-items: start;
  padding-bottom: 20px;
}

.trasselSide {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.caseBody h2 {
  color: var(--purple);
  margin-top: 50px;
}

.casebody p {
  color: #000000;
}

.intro {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 10px;

  align-items: center;
}

.mikael-sign img {
  margin-top: 20px;
  margin-bottom: 20px;
}

.four-columns {
  margin-top: 20px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
}

.four-columns p {
  font-size: 16px;
}

.four-columns a {
  text-decoration: none;
  color: var(--purple);
}

.caseExcerpt {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding: 20px;

  box-shadow: 0px 7px 24px -8px rgba(0,0,0,0.64);
  -webkit-box-shadow: 0px 7px 24px -8px rgba(0,0,0,0.64);
  -moz-box-shadow: 0px 7px 24px -8px rgba(0,0,0,0.64);

  position: relative;
}

.caseExcerpt h2 {
  font-weight: 800;
  margin-top: 0;
}

.caseExcerpt img {
  margin-top: -80px;
  opacity: 0.2;
  margin-bottom: 50px;
  max-width: 100%;

  align-self: end;
  justify-self: end;
}

.caseExcerpt a.case {
  width: calc(100% - 40px);
  background-color: var(--purple);
  padding: 7px 0px;

  color: #ffffff;
  text-decoration: none;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  display: block;
  border-radius: 5px;

  transition: all .2s ease-in;

  position: absolute;
  bottom: 20px;
  left: 20px;
}

.caseExcerpt h3 {
  margin-top: 20px;
  margin-bottom: 20px;
  color: var(--purple);
  font-size: 22px;
}


.caseExcerpt > .three-columns {
  margin-top: 20px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 50px;
  background-color: var(--lightpink);
  border-radius: 20px;
  padding: 20px;
}

.caseExcerpt > .three-columns > img {
  margin-top: 0;
  opacity: 1;
  margin-bottom: 0;
}

.marginParagraph {
  margin-top: 20px;
}

.two-columns {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 20px;
  margin-top: 20px;
}

.caseExcerpt a:hover {
  transform: scale(1.03);
}

.twoColumns {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 20px;

  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding: 20px;

  box-shadow: 0px 7px 24px -8px rgba(0,0,0,0.64);
  -webkit-box-shadow: 0px 7px 24px -8px rgba(0,0,0,0.64);
  -moz-box-shadow: 0px 7px 24px -8px rgba(0,0,0,0.64);

  position: relative;

  margin-top: 30px;
}

.twoColumns h2 {
  margin-top: 0;
}

.twoColumns p {
  line-height: 28px;
  margin-top: 30px;
}

.twoColumns p:first-child {
  margin-top: 0;
}

.twoColumns p span {
  font-size: 20px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: 10px;
  background-color: var(--pink);
  border-radius: 5px;
  font-weight: 800;
}

.twoColumns:first-child {
  margin-top: 30px;
}

.krusidull {
  margin: 0;
}

.produktBody {
  background-color: var(--lightpink);
  border-radius: 25px;
  padding: 20px;
  margin-top: 20px;
}

.produktBody h2 {
  background-color: var(--purple);
  padding: 10px;
  
  display: inline-block;
  text-align: center;
  color: #fff;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.headingContainer {
  text-align: left;
  margin-top: 30px;
}

.leftToRight {
  display: flex;
}

.leftToRight h2 {
  margin-right: 20px;
}

.footer {
  padding: 20px;
  background-color: var(--lightpink);

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
}

.footer > div {
  border-radius: 25px;
  background-color: #fff;
  padding: 20px;
  color: var(--purple);
}

.footer a {
  text-decoration: none;
  font-weight: 500;
  line-height: 30px;
  font-size: 19px;
  color: var(--purple);
}

@media only screen and (max-width: 800px) {
  .footer {
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
  }

  .four-columns {
    grid-template-columns: 1fr;
  }

  .caseContainer {
    display: block;
  }

  .trasselSide {
    display: none;
  }

  .headingContainer {
    text-align: left;
  }

  .mikael {
    display: block;
  }

  .mikael img {
    margin-bottom: 20px;
  }

  .twoColumns {
    display: block;
  }

  .intro {
    display: block;
  }

  .intro h2 {
    margin-top: 0;
  }

  .case {
    display: block;
  }

  .text-wrapper {
    margin-left: 0px;
    margin-top: 10px;
  }

  .two-columns {
    display: block;
  }

  .caseExcerpt {
    margin-bottom: 20px;
  }

  section {
    margin-top: -95px;
    padding-top: 100px;
  }

  .content {
    width: 90%;
    padding-bottom: 20px;
  }

  .container {
    width: 90%;
    padding: 30px 0px;
  }

  .pagePadding {
    width: 95%;
  }

  .textContainer {
    padding: 30px;
    font-size: .95em;
  }

  #a {
    grid-area: a;
  }

  #b {
    grid-area: b;
    text-align: center;
  }

  #c {
    grid-area: c;
  }

  .programs {
    grid-template-columns: 1fr auto;
    grid-template-areas: 
      "a c"
      "b c"
  }

  .bgContainer {
    padding: 30px 10px;
    grid-column-gap: 5px;
  }

  .whiteBg {
    padding: 30px;
  }

  .team {
    display: block; 
  }

  .team p {
    font-size: 16px;
  }

  .member {
    margin-bottom: 30px;
  }

  .menu {
    grid-template-columns: 1fr auto;
    width: 100%;
    position: relative;

    padding: 10px 10px;
  }

  .back-to-top {
    z-index: 3;

    font-size: 40px;
    line-height: 60px;
    width: 70px;
    height: 70px;
    right: 20px;
    bottom: 20px;
  }

  .menu .items {
    display: none;
  }

  .burger {
    display: block;
    font-size: 30px;
  }

  .mobileMenu {
    padding-top: 15px;
  }

  .mobileMenu a {
    color: #fff;
    line-height: 35px;
    text-decoration: none;
  }

  .content {
    position: relative;
    padding-top: 10px;
  }

  .whiteBg h2 {
    font-size: 1.4em;
  }

  .whiteBg p {
    font-size: 0.95em;
  }

  .textContainer h2 {
    font-size: 1.4em;
  }

  h2 {
    font-size: 1.4em;
  }

  .textContainer p {
    font-size: 0.95em;
  }
}