@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: url('./fonts/Montserrat-Light.ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 300;
    src: url('./fonts/Montserrat-LightItalic.ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/Montserrat-Regular.ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 400;
    src: url('./fonts/Montserrat-Italic.ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url('./fonts/Montserrat-Medium.ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 500;
    src: url('./fonts/Montserrat-MediumItalic.ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('./fonts/Montserrat-SemiBold.ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 600;
    src: url('./fonts/Montserrat-SemiBoldItalic.ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('./fonts/Montserrat-Bold.ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 700;
    src: url('./fonts/Montserrat-BoldItalic.ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src: url('./fonts/Montserrat-ExtraBold.ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 800;
    src: url('./fonts/Montserrat-ExtraBoldItalic.ttf');
}